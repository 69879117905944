<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="margin-top: 1rem"
          rounded
          color="light-blue"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-plus</v-icon>
          Create a Purchase Order
        </v-btn>
      </template>
      <v-card>
        <form
          @submit.prevent="
            createPurchaseOrder();
            bodyRequest = {};
          "
        >
          <v-card-title>
            <span class="headline">Create a Purchase Order </span>
          </v-card-title>
          <v-card-text>
            <v-container
              ><v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    required
                    label="Order Notes *"
                    v-model="bodyRequest.notes"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    required
                    label="Supplier"
                    v-model="bodyRequest.supplierId"
                    :items="suppliers"
                    item-text="supplierName"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
              type="submit"
              >Save</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NewPurchaseOrderForm",
  data: () => ({
    bodyRequest: {
      supplierId: 0,
      notes: "",
    },
    dialog: false,
  }),
  props: ["suppliers"],
  methods: {
    ...mapActions("purchaseorderModule", ["createPurchaseOrderAction"]),
    createPurchaseOrder() {
      this.createPurchaseOrderAction(this.bodyRequest);
    },
  },
};
</script>
