<template>
  <v-container>
    <div class="text-h4 mb-10">Purchase Orders</div>
    <v-card class="d-flex justify-center mb-6" flat></v-card>
    <NewPurchaseOrder :suppliers="suppliers" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewPurchaseOrder from "@/components/NewPurchaseOrderForm.vue";

export default {
  name: "PurchaseOrders",
  components: {
    NewPurchaseOrder,
  },
  mounted() {
    this.fetchSuppliers();
  },
  methods: {
    ...mapActions("supplierModule", ["getSuppliersListAction"]),
    fetchSuppliers() {
      this.getSuppliersListAction();
    },
  },
  computed: {
    ...mapGetters("supplierModule", {
      suppliers: "suppliers",
    }),
  },
};
</script>
